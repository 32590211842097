<template>
  <div>
    <!-- types -->
    <app-collapse
      accordion
      type="default"
    >
      <app-collapse
        accordion
        type="margin"
      >
        <app-collapse-item
          v-for="(application,index) in applications['purchase']"
          :key="index"
          :title="`Purchase Lead ${index+1}`"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <strong>Step One</strong>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div
                    v-if="wasAnswered(application['data']['1']['questionOne'],1)"
                    class="col-12"
                  >
                    1){{ application['data']['1']['questionOne']['question'] }}
                  </div>
                  <div class="col-12 mb-1">
                    <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['1']['questionOne']['answers']) }}</span>
                  </div>
                  <div
                    v-if="wasAnswered(application['data']['1']['questionTwo'],1)"
                    class="col-12"
                  >
                    2){{ application['data']['1']['questionTwo']['question'] }}
                    <div class=" mb-1">
                      <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['1']['questionTwo']['answers']) }}</span>
                    </div>
                  </div>

                  <div
                    v-if="wasAnswered(application['data']['1']['questionThree'],1)"
                    class="col-12"
                  >
                    3){{ application['data']['1']['questionThree']['question'] }}
                    <div class=" mb-1">
                      <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['1']['questionThree']['answers']) }}</span>
                    </div>
                  </div>
                  <div
                    v-if="wasAnswered(application['data']['1']['questionFour'],2)"
                    class="col-12"
                  >
                    4){{ application['data']['1']['questionFour']['question'] }}
                    <div class="mb-1">
                      <strong>Ans: </strong> <span>{{ application['data']['1']['questionFour']['userProvidedAns']['city'] }}</span>
                    </div>
                  </div>
                  <div
                    v-if="wasAnswered(application['data']['1']['questionFive'],3)"
                    class="col-12"
                  >
                    5){{ application['data']['1']['questionFive']['question'] }}
                    <div class="mb-1">
                      <strong>Ans: </strong> <span>{{ application['data']['1']['questionFive']['userProvidedAns']['address']['city'] }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <strong>Step Two</strong>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div
                    v-if="wasAnswered(application['data']['2']['questionOne'],1)"
                    class="col-12"
                  >
                    1){{ application['data']['2']['questionOne']['question'] }}
                  </div>
                  <div class="col-12 mb-1">
                    <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['2']['questionOne']['answers']) }}</span>
                  </div>
                  <div
                    v-if="wasAnswered(application['data']['2']['questionTwo'],1)"
                    class="col-12"
                  >
                    2){{ application['data']['2']['questionTwo']['question'] }}
                    <div class=" mb-1">
                      <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['2']['questionTwo']['answers']) }}</span>
                    </div>
                  </div>

                  <div
                    v-if="wasAnswered(application['data']['2']['questionThree'],1)"
                    class="col-12"
                  >
                    3){{ application['data']['2']['questionThree']['question'] }}
                    <div class=" mb-1">
                      <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['2']['questionThree']['answers']) }}</span>
                    </div>
                  </div>
                  <div
                    v-if="wasAnswered(application['data']['2']['questionFour'],2)"
                    class="col-12"
                  >
                    4){{ application['data']['2']['questionFour']['question'] }}
                    <div class="mb-1">
                      <strong>Ans: </strong> <span>{{ application['data']['2']['questionFour']['userProvidedAns']['amount'] }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <strong>Step Three</strong>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div
                    v-if="wasAnswered(application['data']['3']['questionOne'],1)"
                    class="col-12"
                  >
                    1){{ application['data']['3']['questionOne']['question'] }}
                  </div>
                  <div class="col-12 mb-1">
                    <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['3']['questionOne']['answers']) }}</span>
                  </div>
                  <div
                    v-if="wasAnswered(application['data']['3']['questionTwo'],2)"
                    class="col-12"
                  >
                    2){{ application['data']['3']['questionTwo']['question'] }}
                    <div class=" mb-1">
                      <strong>Ans: </strong>
                      <br>
                      <div>First Name{{ application['data']['3']['questionTwo']['userProvidedAns']['first_name'] }}</div>
                      <div>Last Name{{ application['data']['3']['questionTwo']['userProvidedAns']['last_name'] }}</div>
                      <div>Phone{{ application['data']['3']['questionTwo']['userProvidedAns']['phone'] }}</div>
                    </div>
                  </div>

                  <div
                    v-if="wasAnswered(application['data']['3']['questionThree'],1)"
                    class="col-12"
                  >
                    3){{ application['data']['3']['questionThree']['question'] }}
                    <div class=" mb-1">
                      <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['3']['questionThree']['answers']) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <strong>Step Four</strong>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div
                    v-if="wasAnswered(application['data']['4']['questionOne'],2)"
                    class="col-12"
                  >
                    1){{ application['data']['4']['questionOne']['question'] }}
                    <div class=" mb-1">
                      <strong>Ans: </strong>
                      <br>
                      <div><strong>First Name</strong>: {{ application['data']['4']['questionOne']['userProvidedAns']['first_name'] }}</div>
                      <div><strong>Last Name</strong>: {{ application['data']['4']['questionOne']['userProvidedAns']['last_name'] }}</div>
                      <div><strong>Email</strong>: {{ application['data']['4']['questionOne']['userProvidedAns']['last_name'] }}</div>
                      <div><strong>Phone</strong>: {{ application['data']['4']['questionOne']['userProvidedAns']['phone'] }}</div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="col-12"
                  >
                    No questions were answered for this step
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 mt-2">
                    <strong>Attachments</strong>
                  </div>
                </div>
                <div class="row">
                  <div
                    v-for="(attachment,index) in application.attachments"
                    :key="index"
                    class="col-12 mb-1"
                  >
                    <b-badge
                      variant="light-primary"
                    >
                      <feather-icon
                        icon="PaperclipIcon"
                        class="mr-1"
                      />
                      {{ attachment.name }}
                    </b-badge>
                    <hwa-button
                      class="ml-1"
                      variant="flat-primary"
                      icon="DownloadCloudIcon"
                      label="Download"
                      @onClick="downloadAttachment(attachment)"
                    />
                    <hwa-button
                      class="ml-1"
                      variant="flat-danger"
                      icon="XIcon"
                      :icon-only="true"
                      @onClick="removeFile(application['application_id'],attachment.id,'purchase')"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-12 mt-2">
                    <hwa-button
                      class="mr-1"
                      label="Update"
                      variant="flat-primary"
                      @onClick="updateApplication(application['application_id'],'step-1')"
                    />
                    <hwa-file-button
                      label="Attachments"
                      :multiple="true"
                      :loading="uploading"
                      @onFileUpload="(files)=>{uploadFiles(files,application['application_id'],'purchase')}"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-collapse-item>
      </app-collapse>
      <app-collapse-item
        v-for="(application,index) in applications['refinance']"
        :key="index"
        :title="`Refinance Lead ${index+1}`"
      >
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <strong>Step One</strong>
                </div>
              </div>
            </div>
            <div
              v-if="wasAnswered(application['data']['1']['questionOne'],2)"
              class="col-12"
            >
              1){{ application['data']['1']['questionOne']['question'] }}
              <div class=" mb-1">
                <strong>Ans: </strong>
                <br>
                <div><strong>Address :</strong>{{ application['data']['1']['questionOne']['userProvidedAns']['address'] }}</div>
                <div><strong>City :</strong>{{ application['data']['1']['questionOne']['userProvidedAns']['city'] }}</div>
                <div><strong>State :</strong>{{ application['data']['1']['questionOne']['userProvidedAns']['state'] }}</div>
                <div><strong>Unit :</strong>{{ application['data']['1']['questionOne']['userProvidedAns']['unit'] }}</div>
                <div><strong>Zip :</strong>{{ application['data']['1']['questionOne']['userProvidedAns']['zip'] }}</div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <strong>Step Two</strong>
                </div>
              </div>
            </div>
            <div
              v-if="wasAnswered(application['data']['2']['questionOne'],2)"
              class="col-12"
            >
              1){{ application['data']['2']['questionOne']['question'] }}
              <div class="mb-1">
                <strong>Ans: </strong> <span>{{ application['data']['2']['questionOne']['userProvidedAns']['amount'] }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <strong>Step Three</strong>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div
                  v-if="wasAnswered(application['data']['3']['questionOne'],1)"
                  class="col-12"
                >
                  1){{ application['data']['3']['questionOne']['question'] }}
                </div>
                <div class="col-12 mb-1">
                  <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['3']['questionOne']['answers']) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <strong>Step Four</strong>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div
                  v-if="wasAnswered(application['data']['4']['questionOne'],1)"
                  class="col-12"
                >
                  1){{ application['data']['4']['questionOne']['question'] }}
                </div>
                <div class="col-12 mb-1">
                  <strong>Ans: </strong> <span>{{ getSelectedAnswer(application['data']['4']['questionOne']['answers']) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12 mt-2">
                  <strong>Attachments</strong>
                </div>
              </div>
              <div class="row">
                <div
                  v-for="(attachment,index) in application.attachments"
                  :key="index"
                  class="col-12 mb-1"
                >
                  <b-badge
                    variant="light-primary"
                  >
                    <feather-icon
                      icon="PaperclipIcon"
                      class="mr-1"
                    />
                    {{ attachment.name }}
                  </b-badge>
                  <hwa-button
                    class="ml-1"
                    variant="flat-primary"
                    icon="DownloadCloudIcon"
                    label="Download"
                    @onClick="downloadAttachment(attachment)"
                  />
                  <hwa-button
                    class="ml-1"
                    variant="flat-danger"
                    icon="XIcon"
                    :icon-only="true"
                    @onClick="removeFile(application['application_id'],attachment.id,'refinance')"
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 mt-2">
                  <hwa-button
                    class="mr-1"
                    label="Update"
                    variant="flat-primary"
                    @onClick="updateApplication(application['application_id'],'r-step-1')"
                  />
                  <hwa-file-button
                    label="Attachments"
                    :multiple="true"
                    :loading="uploading"
                    @onFileUpload="(files)=>{uploadFiles(files,application['application_id'],'refinance')}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
    <b-card v-if="applications['purchase'].length<1 && applications['refinance'].length<1">
      <div class="row justify-content-center">
        You currently have not filled any questionnaire, click on the button to start a new one.
      </div>
      <div class="row justify-content-center mt-2">
        <hwa-button
          icon="PlusIcon"
          label="Start Questionnaire"
          class=""
          @onClick="newApplication('get-started')"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BBadge, BCard,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaFileButton from '@/layouts/components/HwaFileButton.vue'
import showToast from '@/mixins/showToast'

export default {
  name: 'Questionnaires',
  components: {
    HwaFileButton,
    HwaButton,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    BCard,
  },
  mixins: [showToast],
  data: () => ({
    collapseType: 'default',
    applications: { purchase: [], refinance: [] },
    uploading: false,
  }),
  mounted() {
    this.getApplications()
  },
  methods: {
    downloadAttachment(attachment) {
      const link = document.createElement('a')
      link.download = attachment.name
      link.href = `${process.env.VUE_APP_STORAGE_PATH_MORTGAGE}/${attachment.path}`
      link.target = '_blank'
      link.click()
    },
    updateApplication(id, name) {
      this.$store.state.auth.getStartedCompleted = true
      this.$store.commit('auth/setQuestionsRequestType', {
        updateRequest: true,
        applicationId: id,
      })
      this.$router.push({ name })
    },
    newApplication(name) {
      this.$store.state.auth.getStartedCompleted = true
      this.$store.commit('auth/setQuestionsRequestType', {
        updateRequest: false,
        applicationId: null,
      })
      this.$router.push({ name })
    },
    getSelectedAnswer(answers) {
      const ans = answers.find(an => an.selected)
      return ans?.title
    },
    wasAnswered(question, type) {
      if (type === 1) {
        return question.answers.some(o => o.selected)
      }
      if (type === 2) {
        return Object.values(question.userProvidedAns).every(a => a !== null)
      }
      return Object.values(question.userProvidedAns).every(a => Object.values(a).every(v => v !== null))
    },
    uploadFiles(files, id, type) {
      this.uploading = true
      const attachments = new FormData()
      attachments.append('application_id', id)
      files.forEach(file => {
        attachments.append('attachments[]', file)
      })
      this.$http.post('api/customer/attachments', attachments).then(res => {
        const ap = this.applications[type].find(ap => +ap.application_id === +id)
        ap.attachments = res.data
        this.uploading = false
        this.showSuccess('Uploaded attachment successfully')
      }).catch(err => {
        this.uploading = false
        this.showError('Failed to upload files')
      })
    },
    removeFile(id, attachmentId, type) {
      this.$http.delete(`api/customer/remove_attachments/${attachmentId}`).then(res => {
        const ap = this.applications[type].find(ap => +ap.application_id === +id)
        ap.attachments = ap.attachments.filter(at => +at.id !== attachmentId)
        // this.uploading = false
        this.showSuccess('Attachment was removed successfully')
      }).catch(err => {
        // this.uploading = false
        this.showError('Failed to remove file')
      })
    },
    getApplications() {
      this.$http.get('api/customer_applications').then(res => {
        this.applications = res.data
      })
    },
  },
}
</script>

<style scoped>

</style>
